import { Box, Button, Stack, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { useMutation } from "@apollo/client";
import { toolDisplayNames, ToolEnum, toolOrder } from "@/user/UserRestrictions/common";
import isNil from "@/utis/isNil";
import { useUser } from "@/user/UserContext";
import { PackageTypeEnum } from "@/user/common";
import { useRouter } from "@/navigation";
import { useUserSettingsContextSelector } from "@/user/user-settings/UserSettingsContext";
import { useFormatter, useTranslations } from "next-intl";
import useGetConstants from "@/hooks/useGetConstants";
import useFetchUserPaymentPortal from "@/hooks/useFetchUserPaymentPortal";
import { GET_PAYMENT_URL, GetPaymentUrl, GetPaymentUrlVariables, getPackageName, AvailablePlansProps } from "./common";
import ErrorBoundaryPopup from "../ErrorBoundary/ErrorBoundaryPopup";
import PageSpinner from "../PageSpinner";
import StyledTypography from "../StyledComponents/EllipseTypography";
import InfoModalModal from "../InfoModal/InfoModalModal";
import { LoginType } from "../Login/LoginPopup/common";

const StyledRow = styled(Stack)(
  ({ theme }) => `
`,
);

const StyledColumn = styled(Stack)(
  ({ theme }) => `
	padding-top: ${theme.spacing(1)};
	padding-bottom: ${theme.spacing(1)};
	padding-left: ${theme.spacing(2)};
	padding-right: ${theme.spacing(2)};
	flex-grow: 1;
	flex-basis: 0;
	align-items: center;
	justify-content: end;
  overflow: hidden;
	&:first-of-type
	 {
		max-width: 200px;
    min-width: 100px;
		align-items: start;
		justify-content: center;
	}
  ${theme.breakpoints.down("md")} {
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(1)};
  }
`,
);

const StyledButton = styled(Button, {
  shouldForwardProp: (propName) => propName !== "textColor",
})<{ textColor?: string }>(
  ({ theme, textColor }) => `
  height: 36px;
  font-size: ${theme.typography.body2.fontSize};
  font-weight: bold;
  ${theme.breakpoints.down("md")} {
    padding: ${theme.spacing(0.5)};
  }
  &.Mui-disabled {
    color: ${textColor || theme.palette.text.primary};
  }
  
`,
);

const getRestrictionsLimitText = (limit: number | undefined | null, per?: boolean) => {
  if (isNil(limit)) return <Typography variant="body2">-</Typography>;
  return (
    <Typography variant="body2">
      {limit}
      {per && "%"}
    </Typography>
  );
};


function AvailablePlansDesktop({ isTrial, renewTime, hideTopBtn, ...restProps }: AvailablePlansProps) {
  const user = useUser();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { data: constants, loading: constantLoading } = useGetConstants();
  const openLoginPopup = useUserSettingsContextSelector((ctx) => ctx.openLoginPopup);
  const router = useRouter();
  const restrictions = constants?.restrictions?.filter((res) => res.package !== "LOGGED_IN");

  const [packagedClicked, setpackagedClicked] = useState<PackageTypeEnum>();
  const [openInfo, setOpenInfo] = useState(false);
  const handleOpenInfo = () => {
    setOpenInfo(true);
  };

  const [fetchPaymentUrl, { loading: fetchPaymentUrlLoading }] = useMutation<GetPaymentUrl, GetPaymentUrlVariables>(
    GET_PAYMENT_URL,
  );

  const userHasNoActivePackage =
    isNil(user.data?.activePackageType) ||
    user.data?.activePackageType === PackageTypeEnum.ANONYMOUS ||
    user.data?.activePackageType === PackageTypeEnum.LOGGED_IN;

  const { redirectUserToPaymentPortal, loading: paymentPortalLoading } = useFetchUserPaymentPortal();

  const redirectUserToPayment = (packageType: PackageTypeEnum) => {
    if (userHasNoActivePackage) {
      fetchPaymentUrl({ variables: { packageType } })
        .then((resData) => {
          const paymentUrl = resData.data?.user.purchase;
          if (paymentUrl) {
            router.push(paymentUrl);
          }
        })
        .catch((err) => <ErrorBoundaryPopup message={err.message} />);
    }
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
    if (packagedClicked) {
      redirectUserToPayment(packagedClicked);
    }
    setpackagedClicked(undefined);
  };

  // const isPackageGreaterThenCurrent = (packageType: PackageTypeEnum) => {
  //   const currPackage = user.data?.activePackageType || PackageTypeEnum.ANONYMOUS;
  //   const currPackageIndex = Object.values(PackageTypeEnum).findIndex((val) => val === currPackage);
  //   const packageIndex = Object.values(PackageTypeEnum).findIndex((val) => val === packageType);
  //   return packageIndex > currPackageIndex;
  // };

  const onPackageTypeClick = (packageType: PackageTypeEnum, isCancel?: boolean) => {
    if (!user.isLoggedIn) {
      openLoginPopup(LoginType.SIGN_UP);
      return;
    }
    if (!userHasNoActivePackage && packageType !== (user.data?.activePackageType || PackageTypeEnum.ANONYMOUS)) {
      handleOpenInfo();
      return;
    }

    if (isCancel) {
      redirectUserToPaymentPortal();
    } else {
      redirectUserToPayment(packageType);
    }
  };

  const getButtonText = (isCurrentPlan: boolean, isBottom?: boolean) => {
    if (isCurrentPlan) {
      if (!user.isLoggedIn) return "free";
      if (isBottom) return "cancel_plan";
      return "active";
    }
    // if (isPackageGreaterThenCurrent(packageType)) {
    //   return "Upgrade";
    // }
    return user.isLoggedIn ? "change_plan" : "sign_up";
  };

  const t = useTranslations("plans");
  const wt = useTranslations("words");

  const format = useFormatter();

  return (
    <Stack direction="column" position="relative" {...restProps}>
      <Stack direction="row">
        <StyledColumn maxWidth={120} />
        {restrictions?.map((restriction, i) => {
          const isCurrentPlan = (user.data?.activePackageType || PackageTypeEnum.ANONYMOUS) === restriction.package;
          return (
            <StyledColumn key={i} direction="column">
              <StyledTypography
                color={isCurrentPlan ? undefined : "text.secondary"}
                variant="header"
                fontWeight="bold"
                sx={{ width: "100%" }}
                textAlign="center"
              >
                {getPackageName(restriction.package)}
              </StyledTypography>
              <Typography variant="body1Bold" my={1} color={isCurrentPlan ? undefined : "text.secondary"}>
                ${format.number(restriction.price ?? 0, { currency: "USD", maximumFractionDigits: 0 })}/{isMobile ? "mo" : "Month"}
              </Typography>
              {!hideTopBtn && (
                <StyledButton
                  fullWidth={!isMobile}
                  variant={isCurrentPlan ? "outlined" : "contained"}
                  color="secondary"
                  size="small"
                  disabled={fetchPaymentUrlLoading || paymentPortalLoading}
                  onClick={() => onPackageTypeClick(restriction.package, isCurrentPlan)}
                >
                  {t(getButtonText(isCurrentPlan))}
                </StyledButton>
              )}
              {renewTime && (
                <StyledTypography variant="label" color="text.secondary" fontWeight="bold" mt={0.5}>
                  &nbsp;
                  {isCurrentPlan &&
                    (isTrial ? (
                      <>
                        {t("free_trial_ends")}&nbsp;{renewTime}
                      </>
                    ) : (
                      <>
                        {t("renews")}&nbsp;{renewTime}
                      </>
                    ))}
                </StyledTypography>
              )}
            </StyledColumn>
          );
        })}
      </Stack>
      {toolOrder.map((tool, i) => {
        if (tool === ToolEnum.FREE_BET_CONVERTER) return null;
        if (tool === ToolEnum.ODDS_SCREEN) return (
          <StyledRow direction="row" key={i}>
            <StyledColumn flexDirection="column">
              <Typography fontWeight="bold" variant="header">
                {toolDisplayNames[tool]}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("refresh_time")}
              </Typography>
            </StyledColumn>
            <StyledColumn flexDirection="column">
              <Typography variant="body2" color="text.primary">
                {t("15_Second_Refresh")}
              </Typography>
            </StyledColumn>
            <StyledColumn flexDirection="column">
              <Typography variant="body2" color="text.primary">
                {t("2_4_Second_Refresh")}
              </Typography>
            </StyledColumn>
            <StyledColumn flexDirection="column">
              <Typography variant="body2" color="text.primary">
                {t("2_4_Second_Refresh")}
              </Typography>
            </StyledColumn>
            <StyledColumn flexDirection="column">
              <Typography variant="body2" color="text.primary">
                {t("2_4_Second_Refresh")}
              </Typography>
            </StyledColumn>
          </StyledRow>
        );
        return (
          <StyledRow direction="row" key={i}>
            <StyledColumn flexDirection="column">
              <Typography fontWeight="bold" variant="header">
                {toolDisplayNames[tool]}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("roi_limit")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("max_results")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("sportsbook_limit")}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {t("leagues_limit")}
              </Typography>
            </StyledColumn>
            {restrictions?.map((restriction, ind) => {
              const currRestriction = restriction.restrictions.find((res) => res.toolEnum === tool);
              return (
                <StyledColumn direction="column" key={ind}>
                  {getRestrictionsLimitText(currRestriction?.rOILimit, true)}
                  {getRestrictionsLimitText(currRestriction?.maxResult)}
                  {getRestrictionsLimitText(currRestriction?.sportbookLimit)}
                  {getRestrictionsLimitText(currRestriction?.leagueLimit)}
                </StyledColumn>
              );
            })}
          </StyledRow>
        );
      })}
      <StyledRow direction="row">
        <StyledColumn flexDirection="column">
          <Typography fontWeight="bold" variant="header">
            {t("free_bet_converter")}
          </Typography>
        </StyledColumn>
        <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
          <CheckIcon color="success" fontSize={isMobile ? "small" : "large"} />
        </StyledColumn>
        <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
          <CheckIcon color="success" fontSize={isMobile ? "small" : "large"} />
        </StyledColumn>
        <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
          <CheckIcon color="success" fontSize={isMobile ? "small" : "large"} />
        </StyledColumn>
        <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
          <CheckIcon color="success" fontSize={isMobile ? "small" : "large"} />
        </StyledColumn>
      </StyledRow>
      <StyledRow direction="row">
        <StyledColumn flexDirection="column">
          <Typography fontWeight="bold" variant="header">
            {t("pre_live_odds")}
          </Typography>
        </StyledColumn>
        <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
          <CheckIcon color="success" fontSize={isMobile ? "small" : "large"} />
        </StyledColumn>
        <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
          <CheckIcon color="success" fontSize={isMobile ? "small" : "large"} />
        </StyledColumn>
        <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
          <CheckIcon color="success" fontSize={isMobile ? "small" : "large"} />
        </StyledColumn>
        <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
          <CheckIcon color="success" fontSize={isMobile ? "small" : "large"} />
        </StyledColumn>
      </StyledRow>
      <StyledRow direction="row">
        <StyledColumn flexDirection="column">
          <Typography fontWeight="bold" variant="header">
            {t("portable_filters")}
          </Typography>
        </StyledColumn>
        <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
          <CheckIcon color="success" fontSize={isMobile ? "small" : "large"} />
        </StyledColumn>
        <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
          <CheckIcon color="success" fontSize={isMobile ? "small" : "large"} />
        </StyledColumn>
        <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
          <CheckIcon color="success" fontSize={isMobile ? "small" : "large"} />
        </StyledColumn>
        <StyledColumn direction="column" display="flex" style={{ justifyContent: "center" }}>
          <CheckIcon color="success" fontSize={isMobile ? "small" : "large"} />
        </StyledColumn>
      </StyledRow>
      <StyledRow direction="row">
        <StyledColumn flexDirection="column">
          <Typography fontWeight="bold" variant="header">
            {t("device_limit")}
          </Typography>
        </StyledColumn>
        <StyledColumn direction="column" style={{ justifyContent: "center" }}>
          <Typography variant="body2">1</Typography>
        </StyledColumn>
        <StyledColumn direction="column" style={{ justifyContent: "center" }}>
          <Typography variant="body2">1</Typography>
        </StyledColumn>
        <StyledColumn direction="column" style={{ justifyContent: "center" }}>
          <Typography variant="body2">1</Typography>
        </StyledColumn>
        <StyledColumn direction="column" style={{ justifyContent: "center" }}>
          <Typography variant="body2">1</Typography>
        </StyledColumn>
      </StyledRow>
      <StyledRow direction="row">
        <StyledColumn flexDirection="column">
          <Typography fontWeight="bold" variant="header">
            {t("trial_included")}
          </Typography>
        </StyledColumn>
        {restrictions?.map((restriction, i) => (
          <StyledColumn direction="column" style={{ justifyContent: "center" }} key={i}>
            <Typography variant="body2">
              {isNil(restriction.trialDays) || restriction.price === null ? "-" : t("n_days", { value: restriction.trialDays })}
            </Typography>
          </StyledColumn>
        ))}
      </StyledRow>
      <Stack direction="row">
        <StyledColumn maxWidth={120} />
        {restrictions?.map((restriction, i) => {
          const isCurrentPlan = (user.data?.activePackageType || PackageTypeEnum.ANONYMOUS) === restriction.package;
          return (
            <StyledColumn direction="column" key={i}>
              <StyledButton
                fullWidth={!isMobile}
                variant={isCurrentPlan ? "outlined" : "contained"}
                color="secondary"
                size="small"
                disabled={fetchPaymentUrlLoading || paymentPortalLoading}
                onClick={() => onPackageTypeClick(restriction.package, isCurrentPlan)}
                textColor={isCurrentPlan ? theme.palette.text.secondary : undefined}
              >
                {t(getButtonText(isCurrentPlan, true))}
              </StyledButton>
            </StyledColumn>
          );
        })}
      </Stack>
      {constantLoading && (
        <Box position="absolute" margin="auto" width="100%" height="100%" sx={{ backdropFilter: "blur(2px)" }}>
          <PageSpinner text="Loading" />
        </Box>
      )}

      <InfoModalModal open={Boolean(openInfo)} onClose={handleCloseInfo} width={413} textAlign="center" hideCloseBtn>
        <Typography variant="header" textAlign="center" mt={2}>
          {t("contact_us_title")}
        </Typography>
        <Typography variant="body1" textAlign="center" mt={2}>
          {t("contact_us")}
        </Typography>
        <Button
          variant="outlined"
          sx={{ borderColor: theme.palette.text.secondary, textTransform: "none", mt: 2 }}
          onClick={handleCloseInfo}
        >
          <Typography variant="label" color="text.secondary">
            {wt("close")}
          </Typography>
        </Button>
      </InfoModalModal>
    </Stack>
  );
}

export default AvailablePlansDesktop;
