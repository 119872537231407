import React from "react";
import AvailablePlansMobile from "./AvailablePlansMobile";
import AvailablePlansDesktop from "./AvailablePlansDesktop";
import { AvailablePlansProps } from "./common";

function AvailablePlans(props: AvailablePlansProps) {
  return (
    <>
      <AvailablePlansMobile sx={{ display: { md: "none", xs: "flex" } }} {...props} />
      <AvailablePlansDesktop sx={{ display: { md: "flex", xs: "none" } }} {...props} />
    </>
  );
}

export default AvailablePlans;
